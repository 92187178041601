import { useAuth0 } from "@auth0/auth0-react";
import { Skeleton } from "antd";
import { FC } from "react";
import Card from "../components/common/Card";
import Header from "../components/common/Header";
import Input from "../components/common/Input";
import { useLang } from "../providers/LangProvider";
import { useSupplier } from "../providers/SupplierProvider";
import { useUser } from "../providers/UserProvider";
import "../scss/pages/settings.scss";

interface SettingsProps {}

const Settings: FC<SettingsProps> = () => {
  const { translation } = useLang();
  const { user } = useAuth0();
  const { roles } = useUser();
  const { supplier } = useSupplier();

  return (
    <div className="settings-page">
      <Header
        prefix={translation.settings.title}
        translation={translation}
        username={user?.name ?? ""}
        roles={roles}
      />
      <div className="cards">
        <Card>
          <h2>{translation.settings.supplierInformation}</h2>
          {supplier ? (
            <>
              <Input
                id="name"
                placeholder={translation.settings.nameSupplier}
                defaultValue={supplier?.name}
                readonly
              />
              <Input
                id="address"
                placeholder={translation.settings.addressSupplier}
                defaultValue={`${supplier?.address}, ${supplier?.postcode} ${supplier?.city}`}
                readonly
              />
              <Input
                id="email"
                placeholder={translation.settings.emailSupplier}
                defaultValue={supplier?.mail}
                readonly
              />
            </>
          ) : (
            <>
              <Skeleton />
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Settings;
