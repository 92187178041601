import { useAuth0 } from "@auth0/auth0-react";
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserContextProps } from "../types/user";

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const [roles, setRoles] = useState<any[]>([]);
  const { user } = useAuth0();

  useEffect(() => {
    const roleKey = "https://my.watchbill.app/roles";
    setRoles(user?.[roleKey] || []);
  }, []);

  return (
    <UserContext.Provider value={{ roles }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;

export const useUser = (): UserContextProps => {
  const userContext = useContext<UserContextProps | undefined>(UserContext);

  if (!userContext) {
    throw new Error("You forgot to wrap your component in a userContext");
  }

  return userContext;
};
