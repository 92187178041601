import { useAuth0 } from "@auth0/auth0-react";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { HttpContextProps } from "../types/http.d";
import { initAccessToken } from "./utils/initAccessToken";
import { makeDeleteRequest } from "./utils/makeDeleteRequest";
import { makeGetRequest } from "./utils/makeGetRequest";
import { makePostRequest } from "./utils/makePostRequest";
import { makePutRequest } from "./utils/makePutRequest";

interface HttpProviderProps {
  children: React.ReactNode;
  unAuthenticated?: boolean;
}

const HttpContext = createContext<HttpContextProps | undefined>(undefined);

const HttpProvider: FC<HttpProviderProps> = ({ children, unAuthenticated }) => {
  const [accessToken, setAccessToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    !unAuthenticated &&
      void initAccessToken(getAccessTokenSilently, setAccessToken, setLoading);
  }, [unAuthenticated, getAccessTokenSilently]);

  const get = async (path: string, authenticated?: boolean): Promise<any> =>
    makeGetRequest(path, authenticated ?? true, accessToken);

  const post = async (path: string, body?: any): Promise<any> =>
    makePostRequest(path, body ?? {}, unAuthenticated ?? true, accessToken);

  const del = async (path: string, data?: any): Promise<any> =>
    makeDeleteRequest(path, accessToken, data);

  const put = async (path: string, file: File): Promise<any> =>
    makePutRequest(path, file, accessToken);

  return (
    <HttpContext.Provider
      value={{
        get,
        post,
        del,
        put,
        loading,
      }}
    >
      {children}
    </HttpContext.Provider>
  );
};

export const useHttp = (): HttpContextProps => {
  const httpContext = useContext<HttpContextProps | undefined>(HttpContext);

  if (!httpContext) {
    throw new Error("You forgot to wrap your component in a HttpProvider");
  }

  return httpContext;
};

export default HttpProvider;
