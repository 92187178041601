import { Lang, Translation } from "../types/lang";

export const formatDate = (
  date: string,
  lang: keyof Lang,
  translation: Translation,
): string => {
  const months = translation.common.months;
  const splittedDate: string[] = date.split("-");

  if (
    splittedDate.length !== 3 ||
    isNaN(Number(splittedDate[0])) ||
    isNaN(Number(splittedDate[1])) ||
    isNaN(Number(splittedDate[2]))
  ) {
    return date;
  }

  const [year, month, day] = splittedDate;

  switch (lang) {
    case "de":
      return `${day}. ${months[Number(month) - 1]} ${year}`;
    case "fr":
      return `${day} ${months[Number(month) - 1]} ${year}`;
    default:
      return `${months[Number(month) - 1]}, ${day} ${year}`;
  }
};
