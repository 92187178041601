import {
  IconCheck,
  IconChevronDown,
  IconClock,
  IconX,
} from "@tabler/icons-react";
import { Checkbox, Dropdown, MenuProps, Space } from "antd";
import { FC, useState } from "react";
import { useLang } from "../../providers/LangProvider";

interface StatusFilterProps {
  checkedStatuses: ("paid" | "unpaid" | "late")[];
  setCheckedStatuses: (checkedStatuses: ("paid" | "unpaid" | "late")[]) => void;
}

const StatusFilter: FC<StatusFilterProps> = ({
  checkedStatuses,
  setCheckedStatuses,
}) => {
  const { translation } = useLang();
  const [menuVisible, setMenuVisible] = useState(false);

  const handleOptionToggle = (option: "paid" | "unpaid" | "late") => {
    setCheckedStatuses(
      checkedStatuses.includes(option)
        ? checkedStatuses.filter((status) => status !== option)
        : [...checkedStatuses, option],
    );
  };

  const menuItems: MenuProps["items"] = [
    { key: "paid", label: translation.common.invoiceStatuses.paid },
    { key: "unpaid", label: translation.common.invoiceStatuses.unpaid },
    { key: "late", label: translation.common.invoiceStatuses.late },
  ].map(({ key, label }) => ({
    key,
    label: (
      <span
        className="dropdown-option"
        onClick={(e) => {
          e.stopPropagation();
          handleOptionToggle(key as "paid" | "unpaid" | "late");
        }}
      >
        <Checkbox
          id={key}
          checked={checkedStatuses.includes(key as "paid" | "unpaid" | "late")}
          onChange={(e) => {
            e.preventDefault();
            handleOptionToggle(key as "paid" | "unpaid" | "late");
          }}
        />
        <span>{label}</span>
      </span>
    ),
  }));

  const handleVisibleChange = (flag: boolean) => {
    setMenuVisible(flag);
  };

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={["hover"]}
      open={menuVisible}
      onOpenChange={handleVisibleChange}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {translation.home.filters.status}
          {checkedStatuses.map((status) => (
            <>
              {status === "paid" ? (
                <span className="status-badge paid">
                  <IconCheck size={18} />
                  {translation.common.invoiceStatuses.paid}
                </span>
              ) : (
                <>
                  {status === "unpaid" ? (
                    <span className="status-badge unpaid">
                      <IconX size={18} />
                      {translation.common.invoiceStatuses.unpaid}
                    </span>
                  ) : (
                    <span className="status-badge late">
                      <IconClock size={18} />
                      {translation.common.invoiceStatuses.late}
                    </span>
                  )}
                </>
              )}
            </>
          ))}
          <IconChevronDown />
        </Space>
      </a>
    </Dropdown>
  );
};

export default StatusFilter;
