import { FC } from "react";
import { Translation } from "../../types/lang";

interface HeaderProps {
  prefix: string;
  translation: Translation;
  username: string;
  roles: string[];
}

const Header: FC<HeaderProps> = ({ prefix, username }) => (
  <header>
    <img
      src={`https://api.dicebear.com/9.x/initials/svg?seed=${username}`}
      className="profile-picture"
    />
    <div>
      <h1>
        {prefix}
        <span className="name">{username}</span>
      </h1>
    </div>
  </header>
);

export default Header;
