import { Table } from "antd";
import { FC, useEffect, useState } from "react";
import { useLang } from "../../providers/LangProvider";
import "../../scss/ant-custom.scss";
import Skeleton from "../common/Skeleton";

interface ContactsTableLoadingProps {}

const ContactsTableLoading: FC<ContactsTableLoadingProps> = () => {
  const [data, setData] = useState<Document[]>([]);
  const { translation } = useLang();

  useEffect(() => {
    const formatValues = (): void => {
      const updatedContacts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
        (_, index: number) => ({
          nr: <Skeleton />,
          name_1: <Skeleton />,
          mail: <Skeleton />,
          city: <Skeleton />,
          postcode: <Skeleton />,
          key: index,
        }),
      );

      setData(updatedContacts as any);
    };

    formatValues();
  }, []);

  const columns = [
    {
      title: translation.contacts.contactsTable.contactNumberColumnName,
      dataIndex: "nr",
      key: "nr",
    },
    {
      title: translation.contacts.contactsTable.contactNameColumnName,
      dataIndex: "name_1",
      key: "name_1",
    },
    {
      title: translation.contacts.contactsTable.contactEmailColumnName,
      dataIndex: "mail",
      key: "mail",
    },
    {
      title: translation.contacts.contactsTable.contactCityColumnName,
      dataIndex: "city",
      key: "city",
    },
    {
      title: translation.contacts.contactsTable.contactPostcodeColumnName,
      dataIndex: "postcode",
      key: "postcode",
    },
  ];

  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={columns}
      rowKey={(record: any) => record.key}
    />
  );
};

export default ContactsTableLoading;
