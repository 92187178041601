import { IconUserPlus, IconX } from "@tabler/icons-react";
import { Button, Modal } from "antd";
import { generate } from "generate-password-ts";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import { useHttp } from "../../providers/HttpProvider";
import { useLang } from "../../providers/LangProvider";
import { useSupplier } from "../../providers/SupplierProvider";
import { Contact } from "../../types/contact";

interface ContactCardProps {
  companyId: number;
  contact: Contact;
}

const ContactCard: FC<ContactCardProps> = ({ contact, companyId }) => {
  const [confirmationPopupOpen, setConfirmationPopupOpen] =
    useState<boolean>(false);
  const { translation } = useLang();
  const { post } = useHttp();
  const { supplier } = useSupplier();

  const handleCreateAccount = async (): Promise<void> => {
    setConfirmationPopupOpen(false);

    if (contact) {
      const email = contact.mail;
      const password = generate({
        numbers: true,
        length: 18,
      });

      const promise = post("/contacts", {
        email,
        password,
        userBexioContactId: contact.id,
        bexioContactId: companyId,
        companyName: supplier?.name ?? "",
      });

      toast.promise(promise, {
        pending:
          translation.contacts.createAccountModal.accountCreatingToastText,
        success:
          translation.contacts.createAccountModal.accountCreatedToastText,
        error: translation.contacts.createAccountModal.failedToastText,
      });

      await promise;
    }
  };

  return (
    <>
      <div className="contact-card">
        <span className="contact-name">
          {contact.name_1} {contact.name_2}
        </span>
        <span className="email">{contact.mail}</span>
        <IconUserPlus
          size={23}
          className="button"
          onClick={() => setConfirmationPopupOpen(true)}
        />
      </div>
      <Modal
        title={translation.contacts.createAccountModal.confirmationPopup.title}
        centered
        open={confirmationPopupOpen}
        onOk={() => setConfirmationPopupOpen(false)}
        onCancel={() => setConfirmationPopupOpen(false)}
        width={700}
        footer={
          <>
            <Button
              type="default"
              onClick={() => setConfirmationPopupOpen(false)}
            >
              {translation.contacts.createAccountModal.cancelButtonText}
              <IconX size={20} />
            </Button>
            <Button type="primary" onClick={handleCreateAccount}>
              {translation.contacts.createAccountModal.submitButtonText}
              <IconUserPlus size={20} />
            </Button>
          </>
        }
      >
        <h3>
          {translation.contacts.createAccountModal.confirmationPopup.subtitle}
        </h3>
        <ul>
          <li>
            {
              translation.contacts.createAccountModal.confirmationPopup
                .actionWill1
            }{" "}
            <strong>{contact?.mail}</strong>
          </li>
          <li>
            {
              translation.contacts.createAccountModal.confirmationPopup
                .actionWill2
            }{" "}
            <strong>{contact?.mail}</strong>
          </li>
        </ul>
      </Modal>
    </>
  );
};

export default ContactCard;
