import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "./components/common/Loader";
import Sidebar from "./components/common/Sidebar";
import Contacts from "./pages/Contacts";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Settings from "./pages/Settings";
import HttpProvider from "./providers/HttpProvider";
import InvoicesProvider from "./providers/InvoicesProvider";
import { LangProvider } from "./providers/LangProvider";
import SupplierProvider from "./providers/SupplierProvider";
import UserProvider from "./providers/UserProvider";

interface AppProps {}

const App: FC<AppProps> = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect();
  }

  if (isAuthenticated && !isLoading) {
    return (
      <BrowserRouter>
        <UserProvider>
          <HttpProvider>
            <LangProvider>
              <InvoicesProvider>
                <IntlProvider locale={window.navigator.language}>
                  <SupplierProvider>
                    <ToastContainer
                      position="bottom-right"
                      autoClose={12000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                    <Sidebar />
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/contacts" element={<Contacts />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </SupplierProvider>
                </IntlProvider>
              </InvoicesProvider>
            </LangProvider>
          </HttpProvider>
        </UserProvider>
      </BrowserRouter>
    );
  }

  return <Loader />;
};

export default App;
