import { IconLanguage } from "@tabler/icons-react";
import { FC } from "react";
import { useLang } from "../../providers/LangProvider";

interface LangSwitcherProps {}

const LangSwitcher: FC<LangSwitcherProps> = () => {
  const { lang, toggleLang, translation } = useLang();

  return (
    <div className="select-container">
      <IconLanguage />
      <select value={lang} onChange={(e) => toggleLang(e.target.value as any)}>
        <option value="en">English</option>
        <option value="fr">Français</option>
        <option value="de">Deutsch</option>
        <option value="browser">{translation.common.browser}</option>
      </select>
    </div>
  );
};

export default LangSwitcher;
