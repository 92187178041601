import { FC } from "react";

interface ButtonProps {
  priority: "primary" | "secondary" | "single-icon" | "other";
  customClass?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  type?: "button" | "reset" | "submit";
  style?: React.CSSProperties | undefined;
  onClick?: (...any: any) => any;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
  priority,
  customClass,
  children,
  icon,
  type,
  onClick,
  disabled,
  style,
}) => {
  return (
    <button
      className={`button ${priority} ${customClass}`}
      onClick={onClick}
      disabled={disabled}
      style={style}
      type={type}
    >
      {children}
      {icon}
    </button>
  );
};

export default Button;
