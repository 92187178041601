import { FC } from "react";

interface InputProps {
  id: string;
  placeholder?: string;
  defaultValue?: string;
  type?: React.HTMLInputTypeAttribute;
  onChange?: (...args: any[]) => any;
  readonly?: boolean;
}

const Input: FC<InputProps> = ({
  id,
  placeholder,
  defaultValue,
  type,
  onChange,
  readonly,
}) => (
  <div className="inputGroup">
    <input
      type={type ?? "text"}
      required
      id={id}
      value={defaultValue ?? ""}
      onChange={onChange}
      readOnly={readonly}
    />
    <label className={`${readonly && "readonly"}`} htmlFor={id}>
      {placeholder}
    </label>
  </div>
);

export default Input;
