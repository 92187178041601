import { Modal, Skeleton } from "antd";
import { FC, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useHttp } from "../../providers/HttpProvider";
import { Contact } from "../../types/contact";
import { Translation } from "../../types/lang";
import ContactCard from "./ContactCard";

interface CreateAccountModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  company: Contact | null;
  translation: Translation;
}

const CreateAccountModal: FC<CreateAccountModalProps> = ({
  open,
  setOpen,
  company,
  translation,
}) => {
  const { get, loading } = useHttp();
  const [contacts, setContacts] = useState<Contact[] | null>(null);

  useEffect(() => {
    const initContacts = async (): Promise<void> => {
      const result = await get(`/contacts/companies/${company?.id}/contacts`);

      if (result.contacts) {
        setContacts(result.contacts);
      }
    };

    company && !loading && void initContacts();

    return () => setContacts(null);
  }, [loading, get, company]);

  return (
    <Modal
      title={`${translation.contacts.createAccountModal.title} ${company?.name_1}`}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={1000}
      footer={<></>}
    >
      {contacts !== null ? (
        <form className="modal-form">
          {(contacts?.length ?? 0) > 0 ? (
            <>
              {contacts?.map((c, key) => (
                <ContactCard
                  companyId={company?.id ?? 0}
                  contact={c}
                  key={key}
                />
              ))}
            </>
          ) : (
            <span className="no-contacts">
              Aucun contact n&apos;est associé a cette entreprise
            </span>
          )}
        </form>
      ) : (
        <Skeleton />
      )}
    </Modal>
  );
};

export default CreateAccountModal;
