import { FC } from "react";

interface SkeletonProps {
  className?: string;
}

const Skeleton: FC<SkeletonProps> = ({ className }) => {
  return <div className={`skeleton ${className}`}></div>;
};

export default Skeleton;
