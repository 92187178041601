import { FC, useEffect, useState } from "react";
import { useInvoices } from "../../providers/InvoicesProvider";
import StatusFilter from "./StatusFilter";
import TitleFilter from "./TitleFilter";

interface FiltersBoxProps {}

const FiltersBox: FC<FiltersBoxProps> = () => {
  const [checkedStatuses, setCheckedStatuses] = useState<
    Array<"paid" | "unpaid" | "late">
  >([]);
  const [search, setSearch] = useState<string>("");
  const { allInvoices, setInvoices } = useInvoices();

  useEffect(() => {
    if (!allInvoices) return;

    const filterInvoices = () => {
      if (checkedStatuses.includes("late")) {
        return allInvoices.filter(
          (invoice) =>
            invoice.status !== "paid" &&
            new Date(invoice.is_valid_to).getTime() < new Date().getTime(),
        );
      }

      if (checkedStatuses.length === 0) {
        return allInvoices;
      }

      return allInvoices.filter((invoice) =>
        checkedStatuses.includes(invoice.status as "paid" | "unpaid" | "late"),
      );
    };

    setInvoices(filterInvoices());
  }, [checkedStatuses, allInvoices, setInvoices]);

  useEffect(() => {
    if (!allInvoices) return;

    const filterInvoices = () => {
      return allInvoices.filter((invoice) =>
        invoice.title?.toLowerCase().includes(search.toLowerCase()),
      );
    };

    setInvoices(filterInvoices());
  }, [search, allInvoices, setInvoices]);

  return (
    <div className="filters">
      <TitleFilter search={search} setSearch={setSearch} />
      <StatusFilter
        checkedStatuses={checkedStatuses}
        setCheckedStatuses={setCheckedStatuses}
      />
    </div>
  );
};

export default FiltersBox;
