import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { auth0Config } from "./environment.ts";
import "./scss/root.scss";

Sentry.init({
  dsn: "https://392b626cca4c3caf2e01f44dcc637686@o4507860611039232.ingest.de.sentry.io/4507860612546640",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root") ?? ({} as any)).render(
  <Auth0Provider
    clientId={auth0Config.clientId}
    domain={auth0Config.domain}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: auth0Config.audience,
      logoutUrl: window.location.origin,
    }}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Auth0Provider>,
);
