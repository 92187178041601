import { useAuth0 } from "@auth0/auth0-react";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { Document, InvoicesContextProps } from "../types/invoice";
import { useHttp } from "./HttpProvider";
import { useUser } from "./UserProvider";

interface InvoicesProviderProps {
  children: React.ReactNode;
}

const InvoicesContext = createContext<InvoicesContextProps | undefined>(
  undefined,
);

const InvoicesProvider: FC<InvoicesProviderProps> = ({ children }) => {
  const [allInvoices, setAllInvoices] = useState<Document[] | null>(null);
  const [invoices, setInvoices] = useState<Document[] | null>(null);
  const { loading, get } = useHttp();
  const { user } = useAuth0();
  const { roles } = useUser();

  useEffect(() => {
    const initInvoices = async (): Promise<void> => {
      let result;

      if (!roles.some((role) => role === "supplier")) {
        const bexioContactId = (user as any).user_metadata.bexioContactId;
        result = await get(`/invoices/${bexioContactId}`);
      } else {
        result = await get(`/invoices`);
      }

      setInvoices(result.invoices);
      setAllInvoices(result.invoices);
    };

    user && !loading && void initInvoices();
  }, [roles, user, loading, get]);

  return (
    <InvoicesContext.Provider
      value={{
        loading: loading || invoices === null,
        allInvoices,
        invoices,
        setInvoices,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};

export default InvoicesProvider;

export const useInvoices = (): InvoicesContextProps => {
  const invoicesContext = useContext(InvoicesContext);

  if (!invoicesContext) {
    throw new Error("You forgot to wrap your component in a InvoicesProvider");
  }

  return invoicesContext;
};
