import { useAuth0 } from "@auth0/auth0-react";
import { IconLogout2 } from "@tabler/icons-react";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { tabs } from "../../environment";
import icon from "../../images/icon.png";
import { useLang } from "../../providers/LangProvider";
import { useSupplier } from "../../providers/SupplierProvider";
import { useUser } from "../../providers/UserProvider";
import Button from "./Button";
import LangSwitcher from "./LangSwitcher";
import Skeleton from "./Skeleton";
import TabIcon from "./TabIcon";

interface SidebarProps {}

const Sidebar: FC<SidebarProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const { translation } = useLang();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { roles } = useUser();
  const { supplier } = useSupplier();
  const [iconSrc, setIconSrc] = useState<string | null>(null);

  useEffect(() => {
    if (supplier && supplier.has_own_logo && supplier.logo_base64) {
      setIconSrc(`data:image/png;base64,${supplier?.logo_base64}`);
    } else {
      setIconSrc(icon);
    }
  }, [supplier]);

  return (
    <>
      <label className="hamburger">
        <input
          type="checkbox"
          onChange={(e) => setIsMenuOpen(e.target.checked)}
          checked={isMenuOpen}
        />
        <svg viewBox="0 0 32 32">
          <path
            className="line line-top-bottom"
            d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
          ></path>
          <path className="line" d="M7 16 27 16"></path>
        </svg>
      </label>
      <aside className={`sidebar ${isMenuOpen ? "open" : ""}`}>
        <div>
          <header>
            {supplier && iconSrc ? (
              <img
                alt="Company logo"
                className={`icon ${iconSrc === icon && "default"}`}
                src={iconSrc}
                onClick={() => (window.location.href = window.location.origin)}
              />
            ) : (
              <Skeleton />
            )}
          </header>
          <nav>
            {tabs.map((tab, key) => {
              if (tab.neededRoles?.some((role) => !roles.includes(role))) {
                return null;
              }

              return (
                <Button
                  customClass={`${location.pathname === tab.path ? "active" : "inactive"}`}
                  onClick={() => {
                    navigate(tab.path);
                    setIsMenuOpen(false);
                  }}
                  priority="other"
                  key={key}
                >
                  <TabIcon path={tab.path} />
                  {
                    translation.common.tabs[
                      tab.id as keyof typeof translation.common.tabs
                    ]
                  }
                </Button>
              );
            })}
            <Button
              priority="other"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              <IconLogout2 />
              {translation.common.tabs.logout}
            </Button>
          </nav>
        </div>
        <LangSwitcher />
      </aside>
    </>
  );
};

export default Sidebar;
