import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";
import Header from "../components/common/Header";
import FiltersBox from "../components/invoices/FiltersBox";
import InvoicesTable from "../components/invoices/InvoicesTable";
import InvoicesTableLoading from "../components/invoices/InvoicesTableLoading";
import { useInvoices } from "../providers/InvoicesProvider";
import { useLang } from "../providers/LangProvider";
import { useUser } from "../providers/UserProvider";
import "../scss/pages/home.scss";

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const { loading, invoices } = useInvoices();
  const { translation } = useLang();
  const { user } = useAuth0();
  const { roles } = useUser();

  return (
    <div className="homepage">
      <Header
        prefix={translation.home.title}
        translation={translation}
        username={user?.name ?? ""}
        roles={roles}
      />
      <FiltersBox />
      {(!invoices || loading) && <InvoicesTableLoading />}
      {!loading && <InvoicesTable invoices={invoices ?? []} />}
    </div>
  );
};

export default Home;
