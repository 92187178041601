import { Table } from "antd";
import { FC, useEffect, useState } from "react";
import { useLang } from "../../providers/LangProvider";
import "../../scss/ant-custom.scss";
import Skeleton from "../common/Skeleton";

interface InvoicesTableLoadingProps {}

const InvoicesTableLoading: FC<InvoicesTableLoadingProps> = () => {
  const [data, setData] = useState<Document[]>([]);
  const { translation } = useLang();

  useEffect(() => {
    const formatValues = (): void => {
      const updatedInvoices = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
        (_, index: number) => ({
          title: <Skeleton />,
          total: <Skeleton />,
          is_valid_from: <Skeleton />,
          is_valid_to: <Skeleton />,
          status: <Skeleton />,
          actions: <Skeleton />,
          key: index,
        }),
      );

      setData(updatedInvoices as any);
    };

    formatValues();
  }, []);

  const columns = [
    {
      title: translation.home.invoicesTable.titleColumnName,
      dataIndex: "title",
      key: "title",
    },
    {
      title: translation.home.invoicesTable.dateColumnName,
      dataIndex: "is_valid_from",
      key: "is_valid_from",
    },
    {
      title: translation.home.invoicesTable.validToColumnName,
      dataIndex: "is_valid_to",
      key: "is_valid_to",
    },
    {
      title: translation.home.invoicesTable.amountColumnName,
      dataIndex: "total",
      key: "total",
    },
    {
      title: translation.home.invoicesTable.statusColumnName,
      dataIndex: "status",
      key: "status",
    },
    {
      title: translation.home.invoicesTable.actionsColumnName,
      dataIndex: "actions",
      key: "actions",
    },
  ];

  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={columns}
      rowKey={(record: any) => record.key}
    />
  );
};

export default InvoicesTableLoading;
