import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import german from "../langs/de.json";
import english from "../langs/en.json";
import french from "../langs/fr.json";
import { Lang, LangContextProps, Translation } from "../types/lang";

interface LangProviderProps {
  children: React.ReactNode;
}

const supportedLanguages: Array<keyof Lang> = ["en", "fr", "de"];

const getInitialLang = (): keyof Lang => {
  const storedLang = window.localStorage.getItem("lang") as keyof Lang;
  if (storedLang && supportedLanguages.includes(storedLang)) {
    return storedLang;
  }

  const browserLang = window.navigator.language.slice(0, 2) as keyof Lang;
  return supportedLanguages.includes(browserLang) ? browserLang : "en";
};

const getTranslation = (lang: keyof Lang): Translation => {
  switch (lang) {
    case "de":
      return german as any;
    case "fr":
      return french as any;
    default:
      return english as any;
  }
};

export const CurrentLangContext = createContext<LangContextProps | undefined>(
  undefined,
);

export const LangProvider: FC<LangProviderProps> = ({ children }) => {
  const [lang, setLang] = useState<keyof Lang>(getInitialLang());
  const [translation, setTranslation] = useState<Translation>(
    getTranslation(lang),
  );

  useEffect(() => {
    window.localStorage.setItem("lang", lang);
    setTranslation(getTranslation(lang));
  }, [lang]);

  const toggleLang = (selectedLang: keyof Lang): void => {
    setLang(selectedLang);
  };

  const contextValue: LangContextProps = {
    lang,
    translation,
    toggleLang,
  };

  return (
    <CurrentLangContext.Provider value={contextValue}>
      {children}
    </CurrentLangContext.Provider>
  );
};

export const useLang = (): LangContextProps => {
  const context = useContext(CurrentLangContext);
  if (!context) {
    throw new Error("useLang must be used within a LangProvider");
  }
  return context;
};
