import {
  IconCheck,
  IconClock,
  IconExternalLink,
  IconX,
} from "@tabler/icons-react";
import { Table } from "antd";
import { FC, useEffect, useState } from "react";
import { FormattedNumber } from "react-intl";
import { useLang } from "../../providers/LangProvider";
import "../../scss/ant-custom.scss";
import { Document } from "../../types/invoice";
import { Translation } from "../../types/lang";
import { formatDate } from "../../utils/formatDate";

interface InvoicesTableProps {
  invoices: Document[];
}

const InvoicesTable: FC<InvoicesTableProps> = ({ invoices }) => {
  const [data, setData] = useState<Document[]>([]);
  const { lang, translation } = useLang();

  useEffect(() => {
    const formatValues = (t: Translation): void => {
      const updatedInvoices = invoices.map((invoice) => {
        let statusText = "";
        if (invoice.status === "paid") {
          statusText = t.common.invoiceStatuses.paid;
        } else {
          statusText = t.common.invoiceStatuses.unpaid;
          if (new Date(invoice.is_valid_to).getTime() < new Date().getTime()) {
            statusText = statusText + " " + t.common.invoiceStatuses.late;
          }
        }

        return {
          ...invoice,
          total: String(Number(invoice.total).toFixed(2)),
          is_valid_from: formatDate(invoice.is_valid_from, lang ?? "en", t),
          is_valid_from_timestamp: new Date(invoice.is_valid_from).getTime(),
          is_valid_to: formatDate(invoice.is_valid_to, lang ?? "en", t),
          is_valid_to_timestamp: new Date(invoice.is_valid_to).getTime(),
          statusText,
          status: (
            <>
              {invoice.status === "paid" ? (
                <span className="status-badge paid">
                  <IconCheck size={18} />
                  {t.common.invoiceStatuses.paid}
                </span>
              ) : (
                <>
                  <span className="status-badge unpaid">
                    <IconX size={18} />
                    {t.common.invoiceStatuses.unpaid}
                  </span>
                  {new Date(invoice.is_valid_to).getTime() <
                    new Date().getTime() && (
                    <span className="status-badge late">
                      <IconClock size={18} />
                      {t.common.invoiceStatuses.late}
                    </span>
                  )}
                </>
              )}
            </>
          ),
          actions: (
            <IconExternalLink
              style={{ cursor: "pointer" }}
              onClick={() => window.open(invoice.network_link)}
            />
          ),
        };
      });

      setData(updatedInvoices as any);
    };

    formatValues(translation);
  }, [translation, invoices]);

  const columns = [
    {
      title: translation.home.invoicesTable.titleColumnName,
      dataIndex: "title",
      key: "title",
    },
    {
      title: translation.home.invoicesTable.dateColumnName,
      dataIndex: "is_valid_from",
      key: "is_valid_from",
      sorter: (a: any, b: any) =>
        a.is_valid_from_timestamp - b.is_valid_from_timestamp,
    },
    {
      title: translation.home.invoicesTable.validToColumnName,
      dataIndex: "is_valid_to",
      key: "is_valid_to",
      sorter: (a: any, b: any) =>
        a.is_valid_to_timestamp - b.is_valid_to_timestamp,
    },
    {
      title: translation.home.invoicesTable.amountColumnName,
      dataIndex: "total",
      key: "total",
      render: (value: string) => (
        <FormattedNumber
          value={parseFloat(value)}
          style="currency"
          currency="CHF"
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      ),
    },
    {
      title: translation.home.invoicesTable.statusColumnName,
      dataIndex: "status",
      key: "status",
    },
    {
      title: translation.home.invoicesTable.actionsColumnName,
      dataIndex: "actions",
      key: "actions",
    },
  ];

  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={columns as any}
      rowKey={(record: any) => record.id}
    />
  );
};

export default InvoicesTable;
