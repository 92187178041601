import { createContext, FC, useContext, useEffect, useState } from "react";
import { useHttp } from "./HttpProvider";

interface SupplierProviderProps {
  children: React.ReactNode;
}

const SupplierContext = createContext<SupplierContextProps | undefined>(
  undefined,
);

const SupplierProvider: FC<SupplierProviderProps> = ({ children }) => {
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const { loading, get } = useHttp();

  useEffect(() => {
    const initSupplierProfile = async (): Promise<void> => {
      const result = await get("/supplier");
      setSupplier(result.supplier);
    };

    !loading && void initSupplierProfile();
  }, [loading, get]);

  return (
    <SupplierContext.Provider
      value={{
        supplier,
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};

export const useSupplier = (): SupplierContextProps => {
  const supplierContext = useContext(SupplierContext);

  if (!supplierContext) {
    throw new Error("You forgot to wrap your component in a SupplierProvider");
  }

  return supplierContext;
};

export default SupplierProvider;
