import { useAuth0 } from "@auth0/auth0-react";
import { FC, useCallback, useEffect, useState } from "react";
import Header from "../components/common/Header";
import Input from "../components/common/Input";
import ContactsTable from "../components/contacts/ContactsTable";
import ContactsTableLoading from "../components/contacts/ContactsTableLoading";
import { useHttp } from "../providers/HttpProvider";
import { useLang } from "../providers/LangProvider";
import { useUser } from "../providers/UserProvider";
import "../scss/pages/contacts.scss";
import { Contact } from "../types/contact";

interface ContactsProps {}

const Contacts: FC<ContactsProps> = () => {
  const [allContacts, setAllContacts] = useState<Contact[] | null>(null);
  const [contacts, setContacts] = useState<Contact[] | null>(null);
  const [search, setSearch] = useState<string>("");
  const { translation } = useLang();
  const { user } = useAuth0();
  const { roles } = useUser();
  const { loading, get } = useHttp();

  useEffect(() => {
    const initContacts = async (): Promise<void> => {
      const result = await get("/contacts/companies");
      setContacts(result.contacts);
      setAllContacts(result.contacts);
    };

    !loading && void initContacts();
  }, [loading, get]);

  const handleSearchChange = useCallback((): void => {
    const normalizedSearch = search.toLowerCase();
    const filteredContacts = allContacts?.filter(
      (contact: Contact) =>
        String(contact.nr)?.includes(normalizedSearch) ||
        contact.name_1?.toLowerCase().includes(normalizedSearch),
    );
    setContacts(filteredContacts ?? []);
  }, [search, allContacts]);

  useEffect(() => {
    handleSearchChange();
  }, [search, handleSearchChange]);

  return (
    <div className="contacts-page">
      <Header
        prefix={translation.contacts.title}
        translation={translation}
        username={user?.name ?? ""}
        roles={roles}
      />
      <div className="search">
        <Input
          id="search"
          placeholder={translation.contacts.searchInputPlaceholder}
          onChange={(e) => setSearch(e.target.value)}
          defaultValue={search}
        />
      </div>
      {(!allContacts || !contacts) && <ContactsTableLoading />}
      {contacts && allContacts && <ContactsTable contacts={contacts} />}
    </div>
  );
};

export default Contacts;
