import { FC } from "react";
import { useLang } from "../../providers/LangProvider";
import Input from "../common/Input";

interface TitleFilterProps {
  search: string;
  setSearch: (search: string) => void;
}

const TitleFilter: FC<TitleFilterProps> = ({ search, setSearch }) => {
  const { translation } = useLang();

  return (
    <Input
      onChange={(e) => setSearch(e.target.value)}
      defaultValue={search}
      placeholder={translation.home.filters.title}
      id="title-filter"
    />
  );
};

export default TitleFilter;
