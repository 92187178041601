import { IconUserPlus } from "@tabler/icons-react";
import { Table } from "antd";
import { FC, useEffect, useState } from "react";
import { useLang } from "../../providers/LangProvider";
import "../../scss/ant-custom.scss";
import { Contact } from "../../types/contact";
import CreateAccountModal from "./CreateAccountModal";

interface InvoicesTableProps {
  contacts: Contact[];
}

const InvoicesTable: FC<InvoicesTableProps> = ({ contacts }) => {
  const [data, setData] = useState<Contact[]>([]);
  const [contact, setContact] = useState<Contact | null>(null);
  const [open, setOpen] = useState(false);
  const { translation } = useLang();

  useEffect(() => {
    const formatValues = (): void => {
      const updatedContacts = contacts.map((contact) => {
        return {
          ...contact,
          actions: (
            <>
              <IconUserPlus
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setContact(contact);
                  setOpen(true);
                }}
              />
            </>
          ),
        };
      });

      setData(updatedContacts as any);
    };

    formatValues();
  }, [contacts]);

  const columns = [
    {
      title: translation.contacts.contactsTable.contactNumberColumnName,
      dataIndex: "nr",
      key: "nr",
    },
    {
      title: translation.contacts.contactsTable.contactNameColumnName,
      dataIndex: "name_1",
      key: "name_1",
    },
    {
      title: translation.contacts.contactsTable.contactEmailColumnName,
      dataIndex: "mail",
      key: "mail",
    },
    {
      title: translation.contacts.contactsTable.contactCityColumnName,
      dataIndex: "city",
      key: "city",
    },
    {
      title: translation.contacts.contactsTable.contactPostcodeColumnName,
      dataIndex: "postcode",
      key: "postcode",
    },
    {
      title: translation.home.invoicesTable.actionsColumnName,
      dataIndex: "actions",
      key: "actions",
    },
  ];

  return (
    <>
      <Table
        className="custom-table"
        dataSource={data}
        columns={columns as any}
        rowKey={(record: any) => record.id}
      />
      <CreateAccountModal
        open={open}
        setOpen={setOpen}
        company={contact}
        translation={translation}
      />
    </>
  );
};

export default InvoicesTable;
