import { IconArrowLeft } from "@tabler/icons-react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/common/Button";
import notFounds from "../images/not-founds.png";
import { useLang } from "../providers/LangProvider";
import "../scss/pages/notFound.scss";

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => {
  const navigate = useNavigate();
  const { translation } = useLang();

  return (
    <div className="not-found">
      <div className="content">
        <div className="title">
          <h1>404</h1>
          <img className="logo_404" src={notFounds} alt="404" />
        </div>
        <h2>{translation.notFound.title}</h2>
        <h4>{translation.notFound.doesNotExist}</h4>
      </div>
      <Button priority="other" onClick={() => navigate("/")}>
        <IconArrowLeft />
        {translation.notFound.backToHomeButtonText}
      </Button>
    </div>
  );
};

export default NotFound;
