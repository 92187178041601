import { IconInvoice, IconSettings, IconUsers } from "@tabler/icons-react";
import { FC } from "react";

interface TabIconProps {
  path: string;
}

const TabIcon: FC<TabIconProps> = ({ path }) => {
  switch (path) {
    case "/":
      return <IconInvoice />;
    case "/contacts":
      return <IconUsers />;
    case "/settings":
      return <IconSettings />;
  }
};

export default TabIcon;
