import axios from "axios";
import { watchBillApiBaseUrl } from "../../environment";

export const makePostRequest = async (
  path: string,
  body: any,
  authenticated: boolean,
  accessToken: string,
): Promise<any> => {
  const requestHeaders = {
    Authorization: "",
  };

  if (authenticated) {
    requestHeaders.Authorization = `Bearer ${accessToken}`;
  }

  const result = await axios.post(`${watchBillApiBaseUrl}${path}`, body, {
    headers: requestHeaders,
  });

  if (
    result.status.toString().split("")[0] !== "2" &&
    result.status.toString() !== "409"
  ) {
    throw result.data.error;
  }

  return result.data;
};
